
import Vue from "vue";

export default Vue.extend({
  name: "Login",

  data: () => ({
    model: {
      login: "" as string,
      password: "" as string
    },
    errorMessage: "" as any,
    showPassword: false as boolean,
    forgot: false as boolean
  }),

  computed: {
    PasswordType(): string {
      if (this.showPassword) {
        return "text";
      }
      return "password";
    },
    rules() {
      return {
        login: [
          (v: string): boolean | string =>
            !!v || (this.$t("auth.validation.required") as string)
        ],
        password: [
          (v: string): boolean | string =>
            !!v.length || (this.$t("auth.validation.required") as string)
        ]
      };
    }
  },

  methods: {
    async signIn(): Promise<void> {
      try {
        if (await (this.$refs.form as any).validate()) {
          const response = await this.$API.auth().login(this.model);
          await this.$store.dispatch("authentication/login", response);
          document.location.href = origin;
        }
      } catch (e) {
        if (e.error === "invalid_credentials") {
          this.errorMessage = this.$t("global_validation.invalid_credentials");
        }
      }
    },
    async forgotPassword(): Promise<void> {
      try {
        console.log("recovery");
      } catch {
        this.errorMessage = this.$t("auth.validation.required") as string;
      }
    },
    togglePasswordType(): void {
      this.showPassword = !this.showPassword;
      this.errorMessage = "";
    },
    toggleForgot(): void {
      this.forgot = !this.forgot;
    },
    submitForm(): void {
      if (this.forgot) {
        this.forgotPassword();
      } else {
        this.signIn();
      }
    }
  }
});
